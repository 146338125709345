import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

import enhance from '../img/enhance-icon.svg'
import maintain from '../img/maintain-icon.svg'
import skills from '../img/skills-icon.svg'
import Button from '../components/Button'
import Helmet from 'react-helmet'

// eslint-disable-next-line
export const ServicesPageTemplate = ({
  title,
  firstInformation,
  secondInformation,
  thirdInformation,
  siteTitle,
}) => {
  return (
    <section>
      <div className="container flex flex-col">
        <Helmet>
          <title>{`${title} | ${siteTitle || 'Content Manager'}`}</title>
          <meta
            name="description"
            content={`${firstInformation.description}`}
          />
          <meta property="og:title" content={`${title} | ${siteTitle}`} />
          <meta
            property="og:description"
            content={`${firstInformation.description}`}
          />
        </Helmet>
        <h1 className="mt-10 mb-8 text-4xl purple-text text-purple font-bold font-playfair md:mt-20 md:text-5xl md:mb-18 lg:text-7xl lg:mb-24">
          {title}
        </h1>
        <div className="flex flex-col lg:flex lg:flex-row">
          <div className="flex flex-col items-center mb-4 lg:flex-1">
            <img
              src={enhance}
              alt="Enhance"
              className="mb-4 w-24 h-26 md:w-44 lg:w-32 lg:h-32 lg:mb-8"
            />
            <h3 className="lg:mb-4">{firstInformation.title}</h3>
            <p className="text-center px-4 md:w-3/4 mb-2 md:text-xl lg:p-0">
              {firstInformation.description}
            </p>
          </div>
          <div className="flex flex-col items-center mb-4 lg:flex-1">
            <img
              src={maintain}
              alt="Maintain"
              className="mb-4 w-24 h-26 md:w-44 lg:w-32 lg:h-32 lg:mb-8"
            />
            <h3 className="lg:mb-4">{secondInformation.title}</h3>
            <p className="text-center px-4 md:w-3/4 mb-2 md:text-xl lg:p-0">
              {secondInformation.description}
            </p>
          </div>
          <div className="flex flex-col items-center lg:flex-1">
            <img
              src={skills}
              alt="Skills"
              className="mb-4 w-24 h-26 md:w-44 lg:w-32 lg:h-32 lg:mb-8"
            />
            <h3 className="lg:mb-4">{thirdInformation.title}</h3>
            <p className="text-center px-4 md:w-3/4 mb-2 md:text-xl lg:p-0">
              {thirdInformation.description}
            </p>
            <div className="mt-8 mb-2 md:mt-12 md:mb-8 lg:hidden">
              <Link className="w-fit self-center" to="/contact">
                <Button name="Get In Touch" />
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden mt-8 mb-4 md:mt-12 md:mb-8 lg:block self-center">
          <Link className="w-fit self-center" to="/contact">
            <Button name="Get In Touch" />
          </Link>
        </div>
      </div>
    </section>
  )
}

ServicesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  firstInformation: PropTypes.object.isRequired,
  secondInformation: PropTypes.object.isRequired,
  thirdInformation: PropTypes.object.isRequired,
}

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ServicesPageTemplate
        title={frontmatter.title}
        firstInformation={frontmatter.firstInformation}
        secondInformation={frontmatter.secondInformation}
        thirdInformation={frontmatter.thirdInformation}
        siteTitle={data.site.siteMetadata.title}
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesPage

export const ServicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        firstInformation {
          title
          description
        }
        secondInformation {
          title
          description
        }
        thirdInformation {
          title
          description
        }
      }
    }
  }
`
